import Vue from 'vue';
import Vuex from 'vuex';
import apiModule from './modules/api/index';
import baseModule from './modules/base/index';
import boxTypeMasterModule from './modules/boxTypeMaster';
import commonModule from './modules/common/index';
import constantMasterModule from './modules/constantMaster/index';
import courierModule from './modules/courier/index';
import courierLimitMasterModule from './modules/courierLimitMaster';
import dashboardModule from './modules/dashboard/index';
import definedModule from './modules/defined/index';
import dummyModule from './modules/dummy/index';
import konpomeisaiModule from './modules/konpoMeisai/index';
import minyukinAnkenModule from './modules/minyukinAnken/index';
import priorityShipModule from './modules/priorityShip/index';
import seiyakuAnkenModule from './modules/seiyakuAnken/index';
import shukkaAnkenKakuteiModule from './modules/shukkaAnkenKakutei/index';
import shukkaHikiWatashiModule from './modules/shukkaJunbiHikiWatashi/index';
import shukkaShoruiSakuseiModule from './modules/shukkaShoruiSakusei/index';
import shukkaZumiAnkenModule from './modules/shukkaZumiAnken/index';
import summaryModule from './modules/summary';
import uiModule from './modules/ui/index';
import upsShipmentModule from './modules/upsShipment/index';

Vue.use(Vuex);

const getSummaryActions = [
  'api/updatePackingInfo',
  'api/uploadPackingDetails',
  'api/autoConsolidateBoxes',
  'api/cancelAnken',
  'priorityShip/registPriorityShipActions',
  'api/updateShipmentConfirmPackings',
  'api/updateScheduledShippingDate',
  'api/updateEachPackingInfo',
  'api/revertShipment',
  'api/addReservationNumber',
  'api/createShipmentFedex',
  'api/cancelShipmentFedex',
  'api/createShipmentUPS',
  'api/cancelShipmentUPS',
  'api/createShipmentDHL',
  'api/cancelShipmentDHL',
  'api/updatePackingPaymentDate',
  'api/updateShipTo',
  'api/updateShipToTempChange',
  'api/updateShipToManualInputs',
  'shukkaJunbiHikiWatashi/updateShukkaHikiWatashiItemsActions',
  'shukkaZumiAnken/updateShukkaZumiAnkenItemsActions',
  'minyukinAnken/updateMinyuukinAnkenItemsActions',
];
const getSummaryPlugin = (store) => {
  store.subscribeAction({
    before: null,
    after: async ({ type }) => {
      if (getSummaryActions.includes(type)) {
        const baseIdList = store.getters['base/currentBases'].map((base) => base.id);
        await store.dispatch('api/getSummary', baseIdList, { root: true });
      }
    },
  });
};

const store = new Vuex.Store({
  modules: {
    api: apiModule,
    summary: summaryModule,
    dashboard: dashboardModule,
    dummy: dummyModule,
    priorityShip: priorityShipModule,
    defined: definedModule,
    ui: uiModule,
    constantMaster: constantMasterModule,
    boxTypeMaster: boxTypeMasterModule,
    konpomeisai: konpomeisaiModule,
    seiyakuAnken: seiyakuAnkenModule,
    shukkaAnkenKakutei: shukkaAnkenKakuteiModule,
    shukkaShoruiSakusei: shukkaShoruiSakuseiModule,
    minyukinAnken: minyukinAnkenModule,
    shukkaZumiAnken: shukkaZumiAnkenModule,
    common: commonModule,
    shukkaJunbiHikiWatashi: shukkaHikiWatashiModule,
    courier: courierModule,
    courierLimitMaster: courierLimitMasterModule,
    upsShipment: upsShipmentModule,
    base: baseModule,
  },
  plugins: [getSummaryPlugin],
});

export default store;
