import moment from 'moment';
import encoding from 'encoding-japanese';
import AWS from 'aws-sdk/global';
import S3 from 'aws-sdk/clients/s3';
import router from '@/router/index';

const searchRoutes = {
  '/seiyakuankenkakunin': {
    key: 'seiyakuAnken',
    value: 0,
  },
  '/shukkaankenkakutei': {
    key: 'shukkaAnkenKakutei',
    value: 1,
  },
  '/shukkashoruisakusei': {
    key: 'shukkaShoruiSakusei',
    value: 2,
  },
  '/shukkajunbihikiwatashi': {
    key: 'shukkaJunbiHikiWatashi',
    value: 3,
  },
  '/shukkazumianken': {
    key: 'shukkaZumiAnken',
    value: 4,
  },
};

export default {
  //
  setSearchConditions({ commit }, { searchConditions, storeName = '' }) {
    commit('setSearchConditions', { searchConditions, storeName });
  },

  resetSearchConditions({ commit }) {
    commit('resetSearchConditions');
  },

  setSearchConditionsInitialValues({ commit }) {
    commit('setSearchConditionsInitialValues');
  },

  downloadResultCSV({ dispatch, rootGetters, getters }, { rowSelected, key }) {
    const path = router?.currentRoute?.path ?? '';
    const params = {
      searchConditions: getters?.searchConditions?.[searchRoutes[path]?.key ?? ''],
      selected: rowSelected ?? [],
      searchPage: searchRoutes[path]?.value,
      sortBy: rootGetters['ui/sort']?.[path] ?? [],
      key,
    };
    dispatch('api/downloadResultCSV', params, { root: true });
  },

  // Timestamp with time zoneをYYYY-MM-DDの形で返す
  getFormatDate(_, date, format = 'YYYY/MM/DD') {
    return date ? moment(date).format(format) : '';
  },

  // Timestamp with time zoneをHH-mmの形で返す
  getFormatTime(_, date) {
    return date ? moment(date).format('HH:mm') : '';
  },
  // Timestamp with time zoneをYYYY/MM/DD HH:mmの形で返す
  getFormatDateTime(_, date) {
    return date ? moment(date).format('YYYY/MM/DD HH:mm') : '';
  },
  // Csv読み込む
  parseCsv(_, file) {
    const csv = require('csvtojson');
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();
        reader.onload = function (e) {
          csv({
            output: 'csv',
          })
            .fromString(e.target.result)
            .then(resolve);
        };
        reader.readAsBinaryString(file);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        reject(error);
      }
    });
  },
  readAndDeflate(_, file) {
    const util = require('util');
    const zlib = require('zlib');
    return new Promise((resolve, reject) => {
      try {
        let reader = new FileReader();
        reader.onload = function (e) {
          const deflate = util.promisify(zlib.deflate);
          return deflate(e.target.result).then((result) => {
            return resolve(result.toString('base64'));
          });
        };
        reader.readAsBinaryString(file);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        reject(error);
      }
    });
  },
  encodingConvert(_, x) {
    return encoding.convert(x, 'unicode', 'sjis').trim().replaceAll('"', '');
  },

  //画面と役割によって編集可能取得
  getRole(_, role) {
    const autho = [
      { page: '成約案件確認', authority: 1 },
      { page: '成約案件確認', authority: 2 },
      { page: '成約案件確認', authority: 9 },
      { page: '梱包明細', authority: 1 },
      { page: '梱包明細', authority: 2 },
      { page: '梱包明細', authority: 9 },
      { page: '優先順位作成', authority: 1 },
      { page: '優先順位作成', authority: 3 },
      { page: '優先順位作成', authority: 9 },
      { page: '出荷案件確定', authority: 1 },
      { page: '出荷案件確定', authority: 9 },
      { page: '出荷書類作成', authority: 1 },
      { page: '出荷書類作成', authority: 9 },
      { page: '出荷準備-引渡', authority: 1 },
      { page: '出荷準備-引渡', authority: 2 },
      { page: '出荷準備-引渡', authority: 9 },
      { page: '出荷済み案件', authority: 1 },
      { page: '出荷済み案件', authority: 9 },
      { page: '未入金案件一覧', authority: 1 },
      { page: '未入金案件一覧', authority: 3 },
      { page: '未入金案件一覧', authority: 9 },
      { page: '定数マスタ', authority: 1 },
      { page: '定数マスタ', authority: 9 },
      { page: '箱タイプマスタ', authority: 1 },
      { page: '箱タイプマスタ', authority: 9 },
      { page: 'クーリエ制限マスタ', authority: 1 },
      { page: 'クーリエ制限マスタ', authority: 9 },
    ];
    const item = autho.find(
      (element) => String(element.page) === String(role.page) && String(element.authority) === String(role.authority),
    );
    return item ? true : false;
  },

  upload(_, params) {
    const file = params.file;
    const s3Info = params.s3Info;
    const keyParams = params.keyParams;
    return Promise.resolve()
      .then(() => {
        return new Promise((resolve, reject) => {
          if (file) {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = function (evt) {
              return resolve(evt.target.result);
            };
            reader.onerror = function (evt) {
              return reject(evt);
            };
          } else {
            return reject({});
          }
        });
      })
      .then((fileContent) => {
        AWS.config.update({
          region: s3Info.region,
          credentials: new AWS.Credentials(
            s3Info.credentials.AccessKeyId,
            s3Info.credentials.SecretAccessKey,
            s3Info.credentials.SessionToken,
          ),
        });
        const s3 = new S3({ signatureVersion: 'v4' });

        const params = {
          Bucket: s3Info.bucket,
          Key: s3Info.prefix_key.format(...keyParams) + '/' + file.name, // File name you want to save as in S3
          Body: fileContent,
          'Content-Type': file.type,
        };

        return s3.upload(params).promise();
      });
  },
};
