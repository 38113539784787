export default {
  setBaseList(store, baseList) {
    const allBasesOption = baseList.length > 1 ? [{ id: 0, name: '全拠点' }] : [];

    const baseItems = baseList.map((base) => ({
      id: base.id,
      name: base.name,
    }));

    const bases = [...allBasesOption, ...baseItems];
    store.commit('setBaseList', bases);
  },
  setCurrentBases(store, base) {
    store.commit('setCurrentBases', base);
  },
};
